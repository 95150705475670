import React from 'react';
import TweenOne from 'rc-tween-one';
import { Link } from 'rc-scroll-anim';

class Header extends React.Component {
  componentWillReceiveProps(nextProps) {
    this.setState({
      scrollY: nextProps.scrollY
    })
  }
  constructor(props) {
    super(...arguments);
    this.state = {
      phoneOpen: false,
      scrollY: window.scrollY
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;

    const { phoneOpen } = this.state;
    const { LinkMenu } = dataSource;
    const navData = LinkMenu.children;
    const navChildren = Object.keys(navData).map((key, i) => {
      const item = navData[key];
      let tag = Link;
      const tagProps = {};
      if (item.to && item.to.match(/\//g)) {
        tagProps.href = item.to;
        tagProps.target = '_blank';
        tag = 'a';
        delete item.to;
      }
      // if(item.goTo && item.to.match(/\//g)) {
      //   tagProps.href = item.goTo;
      //   tagProps.target = '_blank'
      //   tag = 'a';
      //   delete item.goTo;
      // }
      return React.createElement(
        tag,
        { ...item, ...tagProps, key: i.toString() },
        navData[key].children
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <div className={[this.state.scrollY > 1 ? 'addNavClass' :'']}>
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div>
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            {
              isMobile || this.state.scrollY == 0 ? <img width="100%" src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/bRWjME58MA22X78XpwTJ8nc6JdyHxEfi_1691715949908_image.png" alt="img" /> : <img width="100%" src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/GTx64KJxDJwwdyBKs3DJfB2JYD3sZMQK_1691716584912_image.png" alt="img" />
            }
            
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          
          {isMobile && (
            <div className='triangle'></div>
          )}
          <TweenOne
            {...LinkMenu}
            animation={
              isMobile
                ? {
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            {navChildren}
            {
              !isMobile ? <a name="linkNav~lbehu0tacbd" class="menu-item" href="https://shop.wujingsc.com/" target="_blank">云采商城</a>: ''
            }
            {
              !isMobile ? <a name="linkNav~lbehu0tacef" class="menu-item" href="https://builder.wujingsc.com/" target="_blank">建材商城</a>: ''
            }
          </TweenOne>
        </div>
        </div>
      </TweenOne>
      </div>
    );
  }
}

export default Header;
