import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { getChildrenToRender } from './utils';

class Banner5 extends React.PureComponent {
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    // delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
        <video id="video" src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/video.mp4" poster="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-14/K358ZTCTSiGnca55HBhJwDWDyYn53iBb_1692001618331_image.png" muted autoPlay loop x5-playsinline="true" webkit-playsinline="true" x5-video-player-fullscreen="true" ></video>
        <div className='middleCon'>
          <div className='middleCon_box'>
            <div className='middle_title'>一站式建筑辅材数字化采购平台</div>
            <div className="middle_describe">客户至上 / 诚信为本 / 团结实干 / 创新发展</div>
            <div className="middle_consult">
              <div className="middle_btn middle_btn1">
              <a className='middle_btn1_span' href="tel:400-616-9963"><p>电话咨询</p></a>
                <p className='middle_btn_3 middle_btn1_1'>
                  <img className='middle_btn1_1_img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/a5HGK2PiAeJtFKbmTrytN7tC4wrRDRQz_1691716223449_image.png" alt="" />
                  <a href="tel:400-616-9963" style={{color: '#000000'}}>400-616-9963</a>
                </p>
              </div>
              <div className="middle_btn middle_btn2">
                <p>在线咨询</p>
                <div className='middle_btn_3 middle_btn2_1'>
                  <img className='middle_btn2_1_img' src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/dkjAHMbyAM3ADJpxR6ZCSp776NxeyStG_1691719254619_image.png" alt="" />
                  <p className='middle_btn2_1_span'>微信扫码咨询</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
export default Banner5;
