import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Popover } from 'antd';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper lbeouxyg9dm-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: (
      <div>
        <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/GTx64KJxDJwwdyBKs3DJfB2JYD3sZMQK_1691716584912_image.png" />
      </div>
    )
  },
  LinkMenu: {
    className: 'header2-menu lbklkexw95p-editor_css',
    children: [
      // {
      //   name: 'linkNav',
      //   to: 'Banner5_0',
      //   children: '首页',
      //   className: 'menu-item lbklk2a7bl-editor_css',
      // },
      {
        name: 'linkNav~lbehs8nqacf',
        to: 'Content0_0',
        children: '关于五京',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lbehsif02jr',
        to: 'Content1_0',
        children: '五京业务',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lbehsm0gc0j',
        to: 'Teams1_0',
        children: '招商加盟',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lbehtwx7zzp',
        to: 'Feature8_1',
        children: '移动商城',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lbehu0tyjbh',
        to: 'Footer1_0',
        children: '联系我们',
        className: 'menu-item',
      },
      // {
      //   name: 'linkNav~lbehu0tacbd',
      //   to: 'https://shop.wujingsc.com/',
      //   goTo: 'https://shop.wujingsc.com/',
      //   children: '云采商城',
      //   className: 'menu-item',
      // },
      // {
      //   name: 'linkNav~lbehu4m1jf',
      //   to: 'Content5_1',
      //   children: '合作伙伴',
      //   className: 'menu-item',
      // },
      // {
      //   name: 'linkNav~lbklglr13go',
      //   to: 'Content5_4',
      //   children: '战略供应商',
      //   className: 'menu-item lbndte7gi36-editor_css',
      // },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 lbnbdit1rtf-editor_css' },
  page: { className: 'home-page banner5-page lbndsr1n99i-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper lbnbbwdgnx-editor_css',
    children: [
      { name: 'title', children: '产品名', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '产品标语介绍',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '产品的详细说明，如是什么东西之类的文字',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image lbnbc8g0wcp-editor_css',
    children:
      'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/banner2.png',
  },
};
export const Feature60DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature6-wrapper lbeijeo5u4-editor_css',
  },
  OverPack: {
    className: 'home-page feature6 lbeijihc5x-editor_css',
    playScale: 0.3,
  },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper lbeid8rxr4-editor_css',
      barWrapper: {
        className: 'feature6-title-bar-wrapper lbeibtwycr-editor_css',
        children: { className: 'feature6-title-bar lbeicvexglp-editor_css' },
      },
      title: { className: 'feature6-title lbeia0a0mu-editor_css' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit lbeidqmer1l-editor_css',
                children: (
                  <span>
                    <p>家</p>
                  </span>
                ),
              },
              toText: true,
              children: '1293',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>企业信赖之选</p>
                </span>
              ),
            },
          },
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit lbeihz0ind-editor_css',
                children: (
                  <span>
                    <p>位</p>
                  </span>
                ),
              },
              toText: true,
              children: '28121',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>累计服务客户</p>
                </span>
              ),
            },
          },
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit lbeiij197sc-editor_css',
                children: (
                  <span>
                    <p>款</p>
                  </span>
                ),
              },
              toText: true,
              children: '16397',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>热门建筑辅材</p>
                </span>
              ),
            },
          },
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child~lbeimo8bijm',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit lbeiij197sc-editor_css',
                children: (
                  <span>
                    <span>
                      <p>小时</p>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '24',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <span>
                    <p>全程服务</p>
                  </span>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};

export const Content00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper lbhduynf6t-editor_css',
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'bigTitle',
        children: '关于五京',
        className: 'lbhd4px2ci9-editor_css',
      },
      {
        name: 'secondTitle',
        className: 'lbg9umlij0e-editor_css',
        children: (
          <span>
            <p className='secondTitle'>ABOUT·WUJING</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block content0-block-box',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:(
                <span>
                  <a href='https://mp.weixin.qq.com/s/X-eCCB6oFoFjIoTw5OX04w' target="_blank">
                    <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/Sr5HmW46JZksyZtHijZTtRnBebcZcsi7_1691714335714_image.png" />
                  </a>
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block1',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'content',
              children: (
                <span>
                  <p className='content0-title-editor_css'>五京头条</p>
                  <p className='content0-span-editor_css'>
                    <a className='content0-span content0-span1' href='https://mp.weixin.qq.com/s?__biz=Mzg2NTcwNjcxMg==&mid=2247486929&idx=1&sn=6a4baa0b9f2c875622817644c4fb7c44&chksm=ce54ba66f92333703e60c595f47a0d9effecccc2b917c565acd5736fd866feb9f3d17800326d&token=1665862758&lang=zh_CN#rd' target="_blank">协同共进 合作共赢丨签约！五京科技与杭州临平数智建设服务中心签署战略合作协议。</a>
                    <img className='content0-span-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/goods/2023-12-21/QkpXM3cHhXasFzwFrM338NNfQCQzBt64_1703126227671_image.png" />
                  </p>
                  <p className='content0-span-editor_css'>
                    <a className='content0-span content0-span2' href='https://mp.weixin.qq.com/s?__biz=Mzg2NTcwNjcxMg==&mid=2247487178&idx=1&sn=060506024d4de9dce85e3ecb80df6bb4&chksm=ce54b97df923306b211f97a11567f612c93adee0f3edc0450b201421e2cfc469f97ce8c4a273&token=1665862758&lang=zh_CN#rd' target="_blank">喜报！五京科技与江西勤能通达供应链签订3亿元采购订单。</a>
                    <img className='content0-span-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/goods/2023-12-21/Yn5dybaPXNDpfTnijKMp628idAY3bKzD_1703126059391_image.png" />
                  </p>
                  <p className='content0-span-editor_css'>
                    <a className='content0-span content0-span3' href='https://mp.weixin.qq.com/s?__biz=Mzg2NTcwNjcxMg==&mid=2247487307&idx=1&sn=8b24cb89c7cad9cf0329f88bbeebd5ed&chksm=ce54b8fcf92331eac2633265dcdd44e821332ae047a94bca44e7a21bb390adbfc89b0283d66d&token=1605054314&lang=zh_CN#rd' target="_blank">喜报！五京科技与浙江秀州建设签订系统战略合作协议。</a>
                    <img className='content0-span-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/goods/2023-12-25/fxFyh2RQ6RPFKjk3TD4t8AnF7SRZeQNK_1703492241697_image.png" />
                  </p>
                  <p className='content0-span-editor_css'>
                    <a className='content0-span content0-span4' href='https://mp.weixin.qq.com/s?__biz=Mzg2NTcwNjcxMg==&mid=2247487641&idx=1&sn=2336a687a668ad5c0586297138edc35b&chksm=ce54a72ef9232e38c126288b3d7b6b4154b46557ee900baf91e9ddef558b3377d8577ed93697&token=1315249283&lang=zh_CN#rd' target="_blank">五京科技与货拉拉签订战略合作协议！</a>
                    <img className='content0-span-img' src='http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/goods/2024-01-15/TBarWMwxhPQ5beRPct6dy6SXKRW6dyXZ_1705284662929_image.png' />
                  </p>
                  <p className='content0-span-editor_css'>
                    <a className='content0-span content0-span5' href='https://mp.weixin.qq.com/s?__biz=Mzg2NTcwNjcxMg==&mid=2247487620&idx=1&sn=873f0941391df177be3b0d05a4c1790c&chksm=ce54a733f9232e25122a1b39e9f7b1ac6d68dbeb4188271c2470f71746a33eb0e173ecf5cb46&token=1315249283&lang=zh_CN#rd' target="_blank">五京科技喜获高新技术企业认定！</a>
                    <img className='content0-span-img' src='http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/goods/2024-01-15/R2jffc6epf2642izzkwFHKDiwedSka7S_1705284617779_image.png' />
                  </p>
                </span>
              ),
              className: 'lbei0wb3km-editor_css',
            },
          ],
        },
      },
    ],
  },
  childSecondWrapper: {
    className: 'content0-block1-wrapper',
    children: [
      {
        name: 'block3',
        className: 'content0-block3',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item content0-block3-item',
          children: [
            {
              name: 'image',
              className: 'content0-block',
              children:(
                <span>
                  <span>
                    <span className='content0-ting-editor_css'>
                      <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/cTxSxrmbrxBbjHkMHNhEapJ8JQEz86Ce_1691714639542_image.png" />
                      <p className='content0-ting-wj-editor_css'>五京科技</p>
                    </span>
                    <span className='content0-ting-wj-des'>我们是一家专注于为建筑、装修行业和专业分包公司提供建筑辅材的一站式采购平台企业，为建筑企业的采购、管理提供高效优质的服务，助力建筑企业转型升级。</span>
                  </span>
                  
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block4',
        className: 'content0-block4',
        md: 12,
        xs: 24,
        children: {
          className: 'content0-block-item content0-block4-item',
          children: [
            {
              name: 'image',
              className: 'content0-block',
              children:(
                <span>
                  <span>
                    <span className='content0-ting-editor_css'>
                      <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/MkhJZyYhh3sxmCN5zceaTi8jFyjMzQ6c_1691714720427_image.png"/>
                      <p className='content0-ting-wj-editor_css'>五京文化</p>
                    </span>
                    <span className='content0-ting-wj-des' style={{paddingBottom: 0}}>
                      <p><span className='content0-ting-wj-des-item content0-ting-wj-des-item1'>使&nbsp;&nbsp;&nbsp;&nbsp;命</span>用技术创新驱动和助推建筑业发展。</p>
                      <p><span className='content0-ting-wj-des-item'>愿&nbsp;&nbsp;&nbsp;&nbsp;景</span>成为最值得客户信赖的建企数字化服务商。</p>
                      <p><span className='content0-ting-wj-des-item'>价值观</span>客户至上、诚信为本、团结实干、创新发展。</p>
                    </span>
                  </span>
                  
                </span>
              ),
            }
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper lbhduynf6t-editor_css',
  },
  page: { className: 'home-page content1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'bigTitle',
        children: '一站式建材采购，尽享便捷体验',
        className: 'lbhd4px2ci9-editor_css',
      },
      {
        name: 'secondTitle',
        className: 'lbg9umlij0e-editor_css',
        children: (
          <span>
            <p className='secondTitle'>WUJING·BUSINESS</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content1-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content1-block-item',
          children: [
            {
              name: 'image',
              className: 'content1-block-icon',
              children:(
                <span className='content1-block-img-span'>
                  <span>
                    <img className='content1-block-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/d8rb7bFHXGj8bdtJQ2XMcFxeKiGXFYZ3_1691724318768_image.png" />
                  </span>
                  <span className='content1-block-span'>
                    <span className='content1-block-span-inner'>
                      <p className='content1-block-span-title'>海量精选，满足所需</p>
                      <p className='content1-block-span-line'></p>
                      <div className='content1-block-span-on-box'>
                        <p className='content1-block-span-on'><span className='content1-block-span-num'>18</span> 个大类<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'><span className='content1-block-span-num'>1480</span> 个小类商品<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'><span className='content1-block-span-num'>1070万+</span> 商品SKU<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'>对接<span className='content1-block-span-num'>16000+</span>战略供应商，优质货源，尽享一站式采购盛宴。</p>
                      </div>
                    </span>
                  </span>
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block0',
        className: 'content1-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content1-block-item',
          children: [
            {
              name: 'image',
              className: 'content1-block-icon',
              children:(
                <span className='content1-block-img-span'>
                  <span>
                    <img className='content1-block-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/GP7EH57WSKxHPSpZerzNETJykYdbYhSH_1691724346639_image.png" />
                  </span>
                  <span className='content1-block-span'>
                    <span className='content1-block-span-inner'>
                      <p className='content1-block-span-title'>高性价比，物超所值</p>
                      <p className='content1-block-span-line'></p>
                      <div className='content1-block-span-on-box'>
                        <p className='content1-block-span-on'>标准化数字化商品管理，杜绝虚高价格，为您提供全品类高性价比建材。</p>
                        <p className='content1-block-span-on'>明码标价，透明购物，让您安心消费。</p>
                        {/* <p className='content1-block-span-on'>标准数字化产品库<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'>全品类高性价比建材<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'>明码标价<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'>一站式线上直购<span className='content1-block-span-on-h'>,</span></p>
                        <p className='content1-block-span-on'>全流程透明可溯</p> */}
                      </div>
                    </span>
                  </span>
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block0',
        className: 'content1-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content1-block-item',
          children: [
            {
              name: 'image',
              className: 'content1-block-icon',
              children:(
                <span className='content1-block-img-span'>
                  <span>
                    <img className='content1-block-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/rKTXnai42eiNS62Sa5KpJn74xAntwtp2_1691724374025_image.png" />
                  </span>
                  <span className='content1-block-span'>
                    <span className='content1-block-span-inner'>
                      <p className='content1-block-span-title'>贴心服务，全程相伴</p>
                      <p className='content1-block-span-line'></p>
                      <div className='content1-block-span-on-box'>
                        <p className='content1-block-span-on'>专业客户成功团队，一对一专属服务，全程跟踪，快速响应，解决您的任何疑虑。</p>
                        <p className='content1-block-span-on'>无忧退换货售后服务体系，保障您的购物权益。</p>
                      </div>
                    </span>
                  </span>
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block0',
        className: 'content1-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content1-block-item',
          children: [
            {
              name: 'image',
              className: 'content1-block-icon',
              children:(
                <span className='content1-block-img-span'>
                  <span>
                    <img className='content1-block-img' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/yC2kbBrGtRXyNcP8NhWzEdchbhe6dKwa_1691724392631_image.png" />
                  </span>
                  <span className='content1-block-span'>
                    <span className='content1-block-span-inner'>
                      <p className='content1-block-span-title'>高效配送，急速送达</p>
                      <p className='content1-block-span-line'></p>
                      <div className='content1-block-span-on-box'>
                        <p className='content1-block-span-on'>引入专业物流体系，24小时智能配送，72小时厂家送达服务，满足您的急需。</p>
                        <p className='content1-block-span-on'>支持当日达、次日达、急件3小时内送达服务，高效便捷。</p>
                      </div>
                    </span>
                  </span>
                </span>
              ),
            }
          ],
        },
      },
    ],
  },
};
const mallContent = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin: '0 6px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/77b3pTMtrNJKDM8ja83XpW6BDx2k84S2_1691717477844_image.png"/><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', margin:'auto'}}>微信扫码进入商城</p>
  </div>
);
const mallContent2 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin:'0 6px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/fYAwa58JfnpswpYmwB7xR3JekzyDSZDb_1691716513151_image.png" /><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop:'6px'}}>微信扫码进入商城</p>
  </div>
);
const mallContent3 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin:'0 6px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/R6A4n85ZMZAJXsJDQa4t5SzAAYwninWR_1691717098117_image.png"/><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop:'6px'}}>微信扫码进入商城</p>
  </div>
);
const mallContent4 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin:'0 6px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/dQtcFRJsbQNDPtzmGzG756Jy7DmycNC2_1691716389551_image.png" /><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop:'6px'}}>微信扫码进入商城</p>
  </div>
);
const mallContent5 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin:'0 6px'}} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/TDE2cZ8fmJNszcfPk82B6AK2R6AMJMFZ_1691716718091_image.png"/><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop:'6px'}}>微信扫码进入商城</p>
  </div>
);
const mallContent6 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin:'0 6px'}} src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/8n7e8Pa7ewm8zMipERezQdYAbTaEZtCn_1691719278484_image.png"/><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop:'6px'}}>微信扫码进入商城</p>
  </div>
);
const mallContent7 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%', margin:'0 6px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/3yMDWPfWpew7zWdWE7TxdwsGZMdweHfK_1691715002463_image.png" /><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop:'6px'}}>微信扫码进入商城</p>
  </div>
);
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export const Feature80DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature8-wrapper lbhduynf6t-editor_css',
  },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper1',
    children: [
      {
        name: 'bigTitle',
        children: '移动商城',
        className: 'lbhd4px2ci9-editor_css',
      },
      {
        name: 'secondTitle',
        className: 'lbg9umlij0e-editor_css',
        children: (
          <span>
            <p className='secondTitle'>WUJINGMALL</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'feature8-block1',
        md: 16,
        xs: 24,
        children: {
          className: 'feature8-block-item',
          children: [
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span className='feature8_wj_content'>
                      <span>
                        <p className='content_big_title'>小程序矩阵</p>
                        <span className='content_second_title'>项目专有、区域专享，全平台采购下单更便捷</span>
                      </span><br/>
                      <span className='scan_code'>
                        <span className='scan_code_item'>
                          <Popover placement="bottomLeft" title="" trigger="hover">
                            <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/servicer/550001/goods/2470415554208493o7EiXXo.png"/>
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottomLeft" title="" trigger="hover">
                            <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/servicer/550001/goods/24704155546909740Az4dTM.png"/>
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottomLeft" title="" trigger="hover">
                            <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/servicer/550001/goods/247041555534189yULJIl34.png"/>
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottomLeft" title="" trigger="hover">
                            <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/servicer/550001/goods/247041555562091Il5ljzF7.png"/>
                          </Popover>
                        </span>
                        {/* <span className='scan_code_item'>
                          <Popover placement="bottomLeft" content={mallContent} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/dB7KfA8sPcsjAKRYwP5fixEYPBAmfZth_1691716286109_image.png"/>
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={mallContent2} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/znQFRSAcdCyKbZyGDAEPhNbabMDNyCFQ_1691716481430_image.png" />
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={mallContent3} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/Pirc4WX362FyMDE2byrjGmED2tdKpwcp_1691717058283_image.png" />
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={mallContent4} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/Tkrw622TeKsz3SewWQiDMmM4njt5FKfN_1691716359379_image.png" />
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={mallContent5} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/myFE6NwbPbdEmfDnc66JY5CRhETyKyJi_1691716909870_image.png" />
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={mallContent6} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/7jSYtYwQE2kdjA8Tn4NNnkwxTazNC8hZ_1691716436124_image.png"/>
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottomRight" content={mallContent7} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/WQHK7pQkXmHkZJwG65sCnFG4pwfkbbsm_1691716323074_image.png" />
                          </Popover>
                        </span> */}
                      </span>
                      {/* http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/TDE2cZ8fmJNszcfPk82B6AK2R6AMJMFZ_1691716718091_image.png */}
                      <span>
                        <p className='content_big_title'>全品类商品</p>
                        <span className='content_second_title'>建筑辅材全品类覆盖，大型仓库、源头直采。</span>
                      </span><br/>
                      <span className='content_big_title_box'>
                        <p className='content_big_title'>移动端商城</p>
                        <span className='content_second_title'>一部手机轻松实现随时随地随心直采。</span>
                      </span><br/>
                    </span>
                    
                  </span>
                </span>
              ),
              className: 'lbei0wb3km-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'feature8-block',
        md: 8,
        xs: 24,
        children: {
          className: 'feature8-block-item',
          children: [
            {
              name: 'image',
              className: 'feature8-block-img',
              children:(
                <span>
                  <a href='#' target="_blank">
                    <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/servicer/550001/goods/24704155568403216202h3e.png" />
                  </a>
                </span>
              ),
            }
          ],
        },
      },
    ],
  },
  
};
export const Teams10DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper lbg9uv3jqbn-editor_css',
  },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'bigTitle',
        children: '强强联手，实力保障',
        className: 'lbhd4px2ci9-editor_css',
      },
      {
        name: 'secondTitle',
        className: 'lbg9umlij0e-editor_css',
        children: (
          <span>
            <p className='secondTitle'>WUJING·LEAGUE</p>
          </span>
        ),
      },
    ],
  },
  secondTitleWrapper: {
    className: 'second-title-wrapper',
    children: [
      {
        name: 'secondBigTitle',
        children: '行业深耕，用技术创新驱动和助推建筑业发展',
        className: 'secondBigTitle-editor_css',
      }
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      // {
      //   name: 'block0',
      //   className: 'block',
      //   md: 4,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children: (
      //           <span>
      //             <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/cateGory/2023-07-27/pKGyc4DyKe5wp7czApjkksnTSxNFMQdS_1690422112900_image.png"/>
      //           </span>
      //         )
      //       },
      //       {
      //         name: 'content',
      //         className: 'teams1-job lbg9w64vgqr-editor_css',
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <span className='teams1-job-name-position'>
      //                   <p className='teams1-job-name'>吕振勇</p>
      //                   <p className='teams1-job-position'>董事长</p>
      //                 </span>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content lbg9wystv7-editor_css',
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <p>金华永康市工商联副主席、金华永康市混凝土协会会长 </p>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
      // {
      //   name: 'block~lbga4ooowdc',
      //   className: 'block',
      //   md: 4,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children: (
      //           <span>
      //             <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/zAtTrzQW4ydGJn7SFTTQnS4Ye2cNHwDQ_1691715697641_image.png" />
      //           </span>
      //         )
      //       },
      //       {
      //         name: 'content',
      //         className: 'teams1-job lbg9wuaiktk-editor_css',
      //         children: (
      //           <span>
      //             <span>
      //               <span className='teams1-job-name-position'>
      //                 <p className='teams1-job-name'>董勇刚</p>
      //                 <p className='teams1-job-position'>总裁</p>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content lbg9wystv7-editor_css',
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <span>
      //                   <p>
      //                   历任中天建设集团、浙江城建集团等数家大型建设集团高管职务
      //                   </p>
      //                 </span>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
      // {
      //   name: 'block~lbga4llwcks',
      //   className: 'block',
      //   md: 4,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children: (
      //           <span>
      //             <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/image/202312/wen.png" />
      //           </span>
      //         )
      //       },
      //       {
      //         name: 'content',
      //         className: 'teams1-job lbg9wuaiktk-editor_css',
      //         children: (
      //           <span>
      //             <span className='teams1-job-name-position'>
      //               <p className='teams1-job-name'>温德亮</p>
      //               <p className='teams1-job-position'>副总裁</p>
      //             </span>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content lbg9wystv7-editor_css',
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <p>历任阿里巴巴、盘石、复星等互联网和产业集团CTO、CIO职务</p>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
      // {
      //   name: 'block~lbga4lz7x3',
      //   className: 'block',
      //   md: 4,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children: (
      //           <span>
      //             <img src="https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/image/202312/yang.png"/>
      //           </span>
      //         )
      //       },
      //       {
      //         name: 'content',
      //         className: 'teams1-job lbg9wuaiktk-editor_css',
      //         children: (
      //           <span>
      //             <span className='teams1-job-name-position'>
      //               <p className='teams1-job-name'>杨国兴</p>
      //               <p className='teams1-job-position'>副总裁</p>
      //             </span>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content lbg9wystv7-editor_css',
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <p>
      //                 曾任道明光学财务总监、财通证券投行三部业务总监、万联证券投行部区域负责人
      //                 </p>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
    ],
  },
  block1: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block~lbga4n3rnp',
        className: 'block1',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-block-image teams1-first-block-image',
              children: (
                <span>
                  <span>
                    <span>
                      <p className='secondBigTitle-editor_css'>合作供应商<span className='secondBigTitle1-editor_css'>16000+</span><a href="https://supplier.wujingsc.com/#/login" className='apply_css' style={{display: isMobile ? "none" : "block"}} target='blank'>申请入驻</a></p>
                    </span>
                  </span>
                  <img className='secondBigTitleImg-editor_css' src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/j6HcEhZMK7A7xWKJeEJKA6y22SsCjKBD_1691719168134_image.png"/>
                </span>
              ),
            },
          ]
        }
      },
      {
        name: 'block~lbga4n3rnp',
        className: 'block1',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-block-image teams1-second-block-image',
              children: (
                <span>
                  <span>
                    <p className='secondBigTitle-editor_css'>合作客户<span className='secondBigTitle1-editor_css'>1000+</span><a href="https://shop.wujingsc.com/#/cooperation?busitype=OFFICIAL" className='apply_css' style={{display: isMobile ? "none" : "block"}} target='blank'>代理加盟</a></p>
                  </span>
                  <img className='secondBigTitleImg-editor_css' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/x87PDGykDQWfJ3JbwazaffxbRs6y4Q8p_1691715365912_image.png"/>
                </span>
              ),
            },
          ]
        }
      }
    ]
  }
};

const settings = {
  customPaging: function(i) {
    return (
      <a>
        {
          i == 0 ? <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/R37RkD6jHP2Zrt826YEG7imrSYF55WJt_1691718135987_image.png" />:
          i == 1 ? <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/2a8MWdweetQwaC57iD5pWFb786bMN2Cd_1691718195032_image.png" /> :
          i == 2 ? <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/A2hkeBrScEAzn82cz7nQZJ2JWzxHFtEn_1691718232545_image.png" /> :
          i == 3 ? <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/6TZa2dnYzWG2RryDAkemPab5XPGRdFGj_1691718255677_image.png"/> :
          i == 4 ? <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/pcQz8tRnsePiHBQ5z2wWcKzGNN45P8Qi_1691718276170_image.png" /> : <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/sHZTTnpDcXrxZ86PPyKiJSNTSFHpSRis_1691718295877_image.png" />
        }
      </a>
    );
  },
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};
export const Content53DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper lbhduynf6t-editor_css',
  },
  page: { className: 'content5' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
    className: 'content5-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content5-block1',
        md: 15,
        xs: 24,
        children: {
          className: 'content5-block-item',
          children: [
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span className='content5_wj_content'>
                    <Slider {...settings}>
                      <div className='content5-slider-item'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/SMwGGQ4DnCZJaFTazRDSKANf2Yrkn76k_1691719422054_image.png" />
                        <span className='content5-slider-item-text'>杭州西站枢纽建设项目辅材供应商</span>
                      </div>
                      <div className='content5-slider-item'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/Bfn7crPnXrjbY47MFs8QbXfn58HGbFbN_1691719463841_image.png" />
                        <span className='content5-slider-item-text'>华润置地（杭珹未来中心）建筑辅材供应商</span>
                      </div>
                      <div className='content5-slider-item'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/AX7f65CP6TSy8CKQhWeZ5DbFiHSs6bBf_1691719482623_image.png" />
                        <span className='content5-slider-item-text'>华润置地（杭珹未来中心）建筑辅材供应商</span>
                      </div>
                      <div className='content5-slider-item'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/XSmYryAasSS7HhwaBSjGn7GnsCbQztMW_1691719507098_image.png" />
                        <span className='content5-slider-item-text'>杭州亚运电竞馆建筑辅材供应商</span>
                      </div>
                      <div className='content5-slider-item'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/dHb8Tbm5G6MEP6C3ENEeN6ir45erYpaK_1691719527440_image.png" />
                        <span className='content5-slider-item-text'>杭州国家版本馆建设项目建筑辅材供应商</span>
                      </div>
                      <div className='content5-slider-item'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/PCiYGNRbPsFAbsE7NPcBiRxmGAnw2s2X_1691719547289_image.png" />
                        <span className='content5-slider-item-text'>华润雪花啤酒建筑辅材供应商</span>
                      </div>
                    </Slider>
                    </span>
                  </span>
                </span>
              ),
              className: 'lbei0wb3km-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content5-block',
        md: 9,
        xs: 24,
        children: {
          className: 'content5-block-box',
          children: [
            {
              name: 'image',
              className: 'content5-block-span',
              children:(
                <div>
                  <div className='content5-block-span'>
                    <h1 className='content5-big-title'>五京能为您带来什么？</h1>
                    <div>
                      <div>
                        <h1 className='content5-samll-title'>- 采购流程便捷化</h1>
                        <p style={{opacity: '0.8'}}>自有配送中心，自有专车及配送团队实现24H智能配送、急件3小时内送达，全程跟进、快速响应、服务周到</p>
                      </div>
                      <div className='content5-desc'>
                        <h1 className='content5-samll-title'>- 仓储情况信息化</h1>
                        <p style={{opacity: '0.8'}}>采购需求、采购过程、仓储情况、物料需用和实际消耗对比等全流程信息数据可视化管理，让项目流转更清晰</p>
                      </div>
                      <div>
                        <h1 className='content5-samll-title'>- 物资流转透明化</h1>
                        <p style={{opacity: '0.8'}}>从采购计划到领用等全过程跟踪，实现物资“从哪儿来、到哪儿去”的全程精细化跟踪管理，有效降低物资损耗成本</p>
                      </div>
                    </div>
                    <div className='content5-relation'>
                      <div className='content5-relation-tel'>
                        <p><a href="tel:400-616-9963" className='content5-relation-tel-num' style={{color: '#ffffff'}}>400-616-9963</a></p>
                        <p className='content5-relation-tel-rext'>电话咨询客服</p>
                      </div>
                      <div className='content5-relation-scan'>
                        <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/dkjAHMbyAM3ADJpxR6ZCSp776NxeyStG_1691719254619_image.png"/>
                        <p style={{ opacity: '0.7' }}>扫码咨询客服</p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            }
          ],
        },
      },
    ],
  },
};
export const Content51DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper lbhc9r0xci-editor_css',
  },
  page: { className: 'home-page content5 lberotx31nh-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>合作伙伴</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1 lberor5c00n-editor_css',
      },
      {
        name: 'content',
        className: 'title-content lbeqyfkwnsi-editor_css',
        children: '在这里用一段话介绍服务的案例情况',
      },
      {
        name: 'title~lberpjebqgp',
        className: 'lberpmnocgo-editor_css',
        children: (
          <span>
            <p>partners</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper lber2f25v2i-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners1.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8i708l',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners2.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8j7vf8c',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners3.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8jl7gr',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners4.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8jzuo6g',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners5.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8kbgjnh',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners6.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8kngsfl',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners7.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8l4mm79',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners8.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8m0zzf',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners9.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
      {
        name: 'block~lbgc8mgjuju',
        className: 'block lbeqnkfx0y6-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbgc82c2kg-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/partners10.png',
            className: 'lbeqzj96tuj-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbeqlbrcpoj-editor_css',
          },
        },
      },
    ],
  },
};
export const Content54DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper lbhcc5kraqn-editor_css',
  },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>战略供应商</p>
          </span>
        ),
        className: 'title-h1 lbhcazc3qe-editor_css',
      },
      {
        name: 'title~lbha9p98p4m',
        className: 'lbha9pyh6l-editor_css',
        children: (
          <span>
            <p>Strategic suppliers</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper lbhaaxnfud-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic1.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatb58k8p',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic2.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatbhozr',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic3.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatbvggd',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic4.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatc6z11c',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic5.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatckchj8',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic6.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatcvwvkj',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic7.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatd6lfpf',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic8.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhatdnjza',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic9.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
      {
        name: 'block~lbhate78pp',
        className: 'block lbhab42dqg-editor_css',
        md: 4,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lbhafgdp0n-editor_css',
          },
          img: {
            children:
              'https://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/website/strategic10.png',
            className: 'lbhadoeifkd-editor_css',
          },
          content: {
            children: 'Ant Design',
            className: 'lbhaaj6sltc-editor_css',
          },
        },
      },
    ],
  },
};

const content = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/QFzcZZdwaJKSFxk5jNnKax4cQFMGkpnc_1691717149591_image.png"/><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop: '6px'}}>微信扫码查看</p>
  </div>
);

const content1 = (
  <div className='scan_imgs'>
    <img className='scan_img_item' style={{ width: '128px', height: '128px', borderRadius: '50%' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/mpRJZMfcejScdbjHhYKyHEQKTt3Q5brh_1691715665589_image.png" /><br/>
    <p className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', marginTop: '6px'}}>抖音扫码查看</p>
  </div>
);
export const Footer10DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper lbeohbue8rs-editor_css',
  },
  OverPack: { className: 'footer1 lbeoh9r3xrb-editor_css', playScale: 0.2 },
  block: {
    className: 'home-page lbeodts2h2-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block~lbeonjg7duo',
        xs: 24,
        md: 8,
        className: 'block lbeof0e8n5-editor_css',
        title: {
          className: 'logo lbeoejr8j9k-editor_css',
          children:
            'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        childWrapper: {
          className: 'slogan lbhch4vcot8-editor_css',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <span className='feature_wj_content'>
                      <span>
                        <p className='content_big_title' style={{ marginTop : '-16px'}}>五京科技</p>
                        <span className='content_second_title'>一站式建筑辅材数字化采购平台</span>
                      </span><br/>
                      <span className='scan_code'>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={content} title="" trigger="hover">
                            <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/ZYcH3B8wPisiwJRd2xKfYGpFdsccrymD_1691719224928_image.png" />
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <Popover placement="bottom" content={content1} title="" trigger="hover">
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/rN4xAc2e6pmN7wMTNx4xCPxdXEX74p8W_1691715601837_image.png" />
                          </Popover>
                        </span>
                        <span className='scan_code_item'>
                          <a href='https://www.toutiao.com/c/user/token/MS4wLjABAAAAmjc-lMGz7SJxP0feAC6gJgtjP1czKYOnOs0Fm3hi_Fk/?wid=1691741269841' target='_blank'>
                            <img src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/xdM3ZXYCiswkKR3jb2BG2XwikTPirJep_1691741998335_image.png" />
                            </a>
                        </span>
                        {/* <span className='scan_code_item'>
                          <a href='https://www.zhihu.com/people/35-23-53-27' target='_blank'>
                            <img src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/tQh6mYp8mcn5Q77h8sQemX7e4hDXhy7i_1691719305449_image.png" />
                            </a>
                        </span> */}
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lbnd24mdme7-editor_css',
            },
          ],
        },
      },
      {
        name: 'content0',
        xs: 24,
        md: 10,
        className: 'block lbeof0e8n5-editor_css',
        title: {
          className: 'logo lbeoejr8j9k-editor_css',
          children:
            'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        childWrapper: {
          className: 'slogan lbhch4vcot8-editor_css',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p className='content_big_title'>客服热线</p>
                        <span className='content_second_title' style={{ display: 'flex'}}><a href="tel:400-616-9963" style={{color: '#222222'}}>400-616-9963</a></span>
                      </span>
                      <span className='content_address'>
                        <p className='content_address_desc'>公司地址</p>
                        <p className='content_address_item'>浙江省杭州市余杭区仓前街道舒心路359路正元智慧大厦A幢603室</p>
                      </span>
                      <span className='content_address'>
                        <p className='content_address_desc'>仓库地址</p>
                        <p className='content_address_item'>浙江省嘉兴市海宁市启二路1号浙江天和典尚集团园区</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lbeonl7mww-editor_css',
            },
          ],
        },
      },
      {
        name: 'block~lbeonjg7duo',
        xs: 24,
        md: 6,
        className: 'block lbeof0e8n5-editor_css',
        title: {
          className: 'logo lbeoejr8j9k-editor_css',
          children:
            'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        childWrapper: {
          className: 'slogan lbhcgcpeyds-editor_css',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span className='online_scan'>
                            <img className='online_scan_code' src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/QwNXPjSZbY2zAfsRZ6sGeCccDTC6j6xr_1691714920133_image.png"/>
                            <p className='online_scan_span'>扫码线上咨询</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lbeonl7mww-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper lbeogz1gge-editor_css' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2018 by <a href="https://motion.ant.design">Ant Motion</a> All Rights
        Reserved
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper lbhj0f0pkvh-editor_css',
  },
  OverPack: {
    className: 'home-page footer0 lbhj3w7x8lg-editor_css',
    playScale: 0.05,
  },
  copyright: {
    className: 'copyright lbhixjswdva-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  {/* <p>用户协议 ｜ 隐私政策</p> */}
                  {/* <p>浙ICP备16025252号-1 | 质量管理体系认证：00121Q37568R1M/3300 | 食品经营许可证：JY13301060151590 | 增值电信业务经营许可证 浙B2-20170171</p>
                  <p>出版物经营许可证：新出发（杭西）零字第00040号 | 对外贸易经营者备案：04365050 | 第二类医疗器械经营备案凭证：浙杭食药监械经营备20190395号</p>
                  <p>营业执照：91330106352469267R</p>
                  <p>浙公网安备 33010602009824 号 | </p> */}
                  <p>浙江五京科技集团有限公司 浙IPC备2021007944号-1</p>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
