/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { BackTop, Popover } from 'antd';
import { enquireScreen } from 'enquire-js';

import Nav2 from './Nav2';
import Banner5 from './Banner5';
import Feature6 from './Feature6';
import Content0 from './Content0';
import Content1 from './Content1';
import Feature8 from './Feature8';
import Teams1 from './Teams1';
import Content5 from './Content5';
import Footer1 from './Footer1';
import Footer0 from './Footer0';

import {
  Nav20DataSource,
  Banner50DataSource,
  Feature60DataSource,
  Content00DataSource,
  Content10DataSource,
  Feature80DataSource,
  Teams10DataSource,
  Content53DataSource,
  Content51DataSource,
  Content54DataSource,
  Footer10DataSource,
  Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      scrollY: window.scrollY
    };
  }
  goSupplierBackstage = (e) => {
    const url = "http://gys.wujingsc.com/#/login";
    window.open(url)
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ scrollY: window.scrollY });
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = () => {
    this.setState({ scrollY: window.scrollY });
  };

  render() {
    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
        scrollY={this.state.scrollY}
      />,
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner50DataSource}
        isMobile={this.state.isMobile}
        scrollY={this.state.scrollY}
      />,
      // <Feature6
      //   id="Feature6_0"
      //   key="Feature6_0"
      //   dataSource={Feature60DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Feature8
      //   id="Feature8_0"
      //   key="Feature8_0"
      //   dataSource={Feature80DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Teams1
        id="Teams1_0"
        key="Teams1_0"
        dataSource={Teams10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content5
        id="Content5_3"
        key="Content5_3"
        dataSource={Content53DataSource}
        isMobile={this.state.isMobile}
      />,
      <div id="Feature8_1"
      key="Feature8_1" style={{height: '1px'}}></div>,
      <Content1
        id="Feature8_0"
        key="Feature8_0"
        dataSource={Feature80DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content5
      //   id="Content5_1"
      //   key="Content5_1"
      //   dataSource={Content51DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Content5
      //   id="Content5_4"
      //   key="Content5_4"
      //   dataSource={Content54DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    const style = {
      height: 140,
      width: 60,
      lineHeight: '40px',
      borderRadius: 30,
      backgroundColor: '#FFFFFF',
      color: '#fff',
      textAlign: 'center',
      fontSize: 14,
      boxShadow: '0px 6px 30px 5px rgba(0,0,0,0.05), 0px 16px 24px 2px rgba(0,0,0,0.04), 0px 8px 10px -5px rgba(0,0,0,0.08)'
    };
    const style1 = {
      color: '#666666',
      fontSize: '400',
      lineHeight: '18px',
      marginTop: '2px',
      fontSize: '12px'
    }
    const lineStyle = {
      width: '48px',
      height: '1px',
      backgroundColor: '#DEDEDE',
      margin: '10px 0px 9px 6px',
    }
    const content = (
      <div className='scan_imgs'>
        <img className='scan_img_item' style={{ width: '84px', height: '84px' }} src="http://test-wuj-bucket.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/dkjAHMbyAM3ADJpxR6ZCSp776NxeyStG_1691719254619_image.png"/><br/>
        <span className='scan_img_span' style={{ fontSize: '12px', color: '#666666', lineHeight: '17px', textAlign: 'center', margin: '0 6px'}}>微信扫码咨询</span>
      </div>
    );
    const content1 = (
      <div className='scan_imgs'>
        <p style={{marginBottom: '4px', color: '#1A1A1A', lineHeight: '17px'}}><a href="tel:400-616-9963" style={{color: '#1A1A1A', fontWeight: 600}}>400-616-9963</a></p>
        <p style={{marginBottom: 0, lineHeight: '17px', color: '#666666'}}>24小时客服热线</p>
      </div>
    );
    const content2 = (
      <div className='scan_imgs'>
        <img className='scan_img_item' style={{ width: '84px', height: '84px' }} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/77b3pTMtrNJKDM8ja83XpW6BDx2k84S2_1691717477844_image.png"/><br/>
      </div>
    );
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (
      <div>
        <div className={['box-ant-back-top1']} style={{display: isMobile ? "none" : "block"}}>
          <div style={style} className={[this.state.scrollY > 400 ? 'header2Show' :'header2Hidden']}>
            <div>
              <div style={{ lineHeight: 'normal'}}>
                  <Popover placement="left" content={content2} title="" trigger="hover">
                    <img style={{marginTop: '16px', width: '24px', height: '24px'}} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/mYeJ8Z26F8A8jEc8CBHYd3hQGrbnZW5e_1691716552233_image.png" /><br/>
                    <div style={style1}>我要采购</div>
                  </Popover>
              </div>
              <p style={lineStyle}></p>
              <div style={{ lineHeight: 'normal'}} onClick={(e) => { this.goSupplierBackstage(e);}}>
                  <img style={{width: '24px', height: '24px'}} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/reception/2023-09-15/c6hbhttXsF2ztQAZiPPTypjcMdra7epM_1694766672175_image.png" /><br/>
                  <div style={style1}>我要供货</div>
              </div>
            </div>
          </div>
        </div>
        <div className={['box-ant-back-top']}>
          <div style={style} className={[this.state.scrollY > 400 ? 'header2Show' :'header2Hidden']}>
            <div>
              <div style={{ lineHeight: 'normal'}}>
                <Popover placement="left" content={content} title="" trigger="hover">
                  <img style={{marginTop: '16px', width: '24px', height: '24px'}} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/mYeJ8Z26F8A8jEc8CBHYd3hQGrbnZW5e_1691716552233_image.png" /><br/>
                  <div style={style1}>在线咨询</div>
                </Popover>
              </div>
              <p style={lineStyle}></p>
              <div style={{ lineHeight: 'normal'}}>
                <Popover placement="left" content={content1} title="" trigger="hover">
                  <img style={{width: '24px', height: '24px'}} src="http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/YM7zm7PcC4sREpy8s6kSpKkGbY22WERX_1691716687405_image.png" /><br/>
                  <div style={style1}>电话咨询</div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
      </div>
    );
  }
}
